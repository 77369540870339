import { inView, scroll } from "motion";
import throttle from "../../throttle";

const Header = () => {


  /* Header text colour based on section below - old version */
  /*
  const header = document.querySelector("header > div[x-data='Header'] > div");
  const sections = document.querySelectorAll(
    "section.has-primary-background-color, section.has-secondary-background-color, .giantpeach-stickyscroll.has-primary-background-color, .giantpeach-stickyscroll.has-secondary-background-color, .giantpeach-footer",
  );

  if (document.body.classList.contains('page-id-255')) {
    header.classList.add("has-background");
    header.classList.add("has-background-primary");
    return false;
  }

  sections.forEach(section => {
    inView(section, ({ target }) => {
      const isPrimary = section.classList.contains('has-primary-background-color');
      const isSecondary = section.classList.contains('has-secondary-background-color');

      setTimeout(_ => {
        header.classList.add("has-background");

        if (isPrimary) {
          header.classList.add("has-background-primary");
        } else if (isSecondary) {
          header.classList.add("has-background-secondary");
        }
      }, 150);


      return (leaveInfo) => {
        header.classList.remove("has-background");
        header.classList.remove("has-background-primary");
        header.classList.remove("has-background-secondary");
      }
    }, {margin: "-10px 0px -10px 0px"});
  });

  */

  const header = document.querySelector("header > div[x-data='Header']");

  if (document.body.classList.contains('page-id-255')) {
    // White nav text on contact page
    header.classList.add("has-background");
    header.classList.add("has-background-primary");
  }

  let lastScrollTop = 0;

  const scrollFunction = scrollableNode => {
    const scrollTop = scrollableNode.scrollTop ? scrollableNode.scrollTop : scrollableNode.scrollY;

    // After 60px translate/top / hide on scroll down
    if (scrollTop >= 60) {
      header.classList.add('nav-is-sticky');

      if (scrollTop >= lastScrollTop) {
        header.classList.remove('!transform-none');
        header.classList.add('-translate-y-full');

      } else {
        header.classList.add('!transform-none');
      }

      lastScrollTop = scrollTop;
    } else {
      header.classList.remove('nav-is-sticky');
      header.classList.remove('!transform-none');            
      header.classList.remove('-translate-y-full');
    }
  }

  const scrollWrapper = document.querySelector('.wp-site-blocks');

  scrollWrapper.addEventListener('scroll', _ => throttle(scrollFunction(scrollWrapper), 50));
  window.addEventListener('scroll', _ => throttle(scrollFunction(window), 100));
};

export default Header;
