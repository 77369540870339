import { inView, animate } from "motion";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import persist from "@alpinejs/persist";
import Header from "./Blocks/Header/header.js";
import "./main.css";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

Alpine.plugin(intersect);
Alpine.plugin(persist);

window.Alpine = Alpine;
Alpine.start();

const domReady = () => {
  let transitionableBlocks = document.querySelectorAll(".transition-block");
  const markContainers = document.querySelectorAll("[data-mark-type]");
  const stickyScrolls = document.querySelectorAll(".giantpeach-stickyscroll");
  const carousels = document.querySelectorAll(".carousel");

  Header();

  let inViewStop = inView(
    transitionableBlocks,
    ({ target }) => {
      target.classList.add("is-visible");

      return () => {
        target.classList.remove("is-visible");
      };
    },
    { margin: "0px 0px -15% 0px" },
  );

  document.addEventListener("block:load", () => {
    transitionableBlocks = document.querySelectorAll(".transition-block");
    inViewStop();
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      target.classList.add("is-visible");

      return () => {
        target.classList.remove("is-visible");
      };
    });
  });

  if (markContainers.length) {
    import("./marks").then(({ default: marks }) => {
      marks();
    });
  }

  if (stickyScrolls.length) {
    import("./Blocks/StickyScroll/sticky-scroll").then(
      ({ default: stickyScroll }) => {
        stickyScrolls.forEach((stickyScrollNode) => {
          stickyScroll(stickyScrollNode);
        });
      },
    );
  }

  if (carousels.length) {
    import("./Blocks/Carousel/carousel.js").then(({ default: Carousel }) => {
      Carousel();
    });
  }

  document.addEventListener("scroll:lock", (e) => {
    console.log("scroll:lock", e.detail?.element);
    if (e.detail?.element) {
      disableBodyScroll(e.detail.element);
    }
  });

  document.addEventListener("scroll:unlock", (e) => {
    if (e.detail?.element) {
      enableBodyScroll(e.detail.element);
    } else {
      clearAllBodyScrollLocks();
    }
  });

  /* Button hover animation */
  const buttons = document.querySelectorAll('a.gp-button, button.gp-button');

  const setupBtnNodes = el => {
    const wrapper = document.createElement('span');
    const spanWrap = document.createElement('span');

    wrapper.classList.add('btn-transition');

    spanWrap.innerHTML = el.innerHTML;

    const spanWrapClone = spanWrap.cloneNode(true);
    spanWrap.classList.add('first');
    spanWrapClone.classList.add('second');

    wrapper.appendChild(spanWrap);
    wrapper.appendChild(spanWrapClone);

    el.innerHTML = '';
    el.appendChild(wrapper);
  }
  
  buttons.forEach(el => {
    setupBtnNodes(el);
  });



  /* Scroll */
  const scrollSections = document.querySelectorAll('.giantpeach-stickysection');
  gsap.registerPlugin(ScrollTrigger);

  scrollSections.forEach(el => {
    const container = el.querySelector('.inner');
    const containerInner = container.children[0];

    let pin = true;
    if (window.innerWidth < 1023 && el.classList.contains('mobileStatic')) {
      pin = false;
    }

    gsap.fromTo(containerInner, {autoAlpha: 1}, {
      scrollTrigger: {
        trigger: container,
        start: 'top',
        end: 'bottom 10%',
        toggleClass: "active",
        fastScrollEnd: 1000
      }
    });

    ScrollTrigger.create({
        trigger: container,
        start:  "top",
        end: "bottom",
        pin: pin,
        fastScrollEnd: 1000,
    });
  });
};

document.addEventListener("DOMContentLoaded", domReady);
